import { injectedAccessTokenStorage } from '../../../localStorages/injectedAccessTokenStorage.ts';
import { getWebAuth, goToLogin, isTokenCloseToExpiration } from './authorization.ts';

type AuthorizationProvider = {
  readonly getSnapshot: () => {
    readonly getAuthToken: () => Promise<AuthToken>;
    readonly refreshAuthToken: () => void;
  };
  readonly subscribe: (callback: () => void) => () => void;
};

/**
 * Creates connection between auth token manager and React reactive way of working.
 * Instead of the token itself, a function returning the token is exposed ensuring to return a valid authToken each time the token is required.
 *
 * - 'getSnapshot' and 'subscribe' is an implementation of React useSyncExternalStore API. 'getSnapshot' returns the same immutable reference
 *    until the new token is obtained. React re-renders only when a listener is subscribed and the snapshot has changed.
 *
 * - 'getSnapshot.getAuthToken' returns a promise to get a valid authToken. The callback reference changes every time the token is refreshed
 *    so React components can subscribe to the change.
 *
 * - 'getSnapshot.refreshAuthToken' triggers an immediate token refresh regardless token's current validity.
 */
export function createAuthorizationProvider(): AuthorizationProvider {
  /**
   * The activeCheckSession variable is shared across all getAuthToken instances, so even the code that doesn't react to
   * getAuthToken reference change can have access to the latest pending checkSession promise and the currentToken.
   */
  let activeCheckSession: Promise<AuthToken> | null = null;
  let currentToken: AuthToken | null = null;
  const listeners: Set<() => void> = new Set();
  let contract: ReturnType<AuthorizationProvider['getSnapshot']> = Object.freeze({
    getAuthToken: createGetAuthToken(),
    refreshAuthToken,
  });

  function subscribe(listener: () => void): () => void {
    listeners.add(listener);
    return () => {
      listeners.delete(listener);
    };
  }

  /**
   * Factory, because components referencing getAuthToken need to react to the token change.
   * The single reference would mean that hook deps are stale.
   */
  function createGetAuthToken(): () => Promise<AuthToken> {
    return () => {
      currentToken ??= injectedAccessTokenStorage.load();

      if (currentToken && !isTokenCloseToExpiration(currentToken)) {
        return Promise.resolve(currentToken);
      }

      activeCheckSession ??= new Promise((resolve, reject) => {
        getWebAuth().checkSession({ usePostMessage: true }, (error, authResult) => {
          if (error || !authResult) {
            goToLogin();
            activeCheckSession = null;
            reject(error);
          } else {
            publishToken(authResult.accessToken);
            activeCheckSession = null;
            resolve(authResult.accessToken);
          }
        });
      });

      return activeCheckSession;
    };
  }

  function publishToken(authToken: AuthToken): void {
    currentToken = authToken;
    contract = Object.freeze({
      getAuthToken: createGetAuthToken(),
      refreshAuthToken,
    });
    listeners.forEach((cb) => cb());
  }

  function refreshAuthToken(): void {
    currentToken = null;
    contract.getAuthToken();
  }

  return {
    getSnapshot: () => contract,
    subscribe,
  };
}
