import { injectedAuth0AccessTokenStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { localStorage } from '../_shared/utils/localStorage.ts';

let _accessToken: string | null = null;

export const injectedAccessTokenStorage = {
  save(accessToken: string): void {
    localStorage.set(injectedAuth0AccessTokenStorageKey, accessToken);
    _accessToken = accessToken;
  },

  load(): string | null {
    if (!_accessToken) {
      const auth0AccessToken = localStorage.get(injectedAuth0AccessTokenStorageKey);
      _accessToken = auth0AccessToken ? auth0AccessToken : null;
    }

    return _accessToken;
  },
};
